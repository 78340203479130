import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../layout/Layout";
import myimage from "../../assets/myimage.jpg";
import "animate.css";
import useInView from "../../components/useInView"; // Import the custom hook
import { Link } from "react-router-dom";
import toro_image from "../../assets/toro_image.png";
import trivia_image from "../../assets/trivia_image.png";
import LetterOfCompletion_Springworks from "../../assets/LetterOfCompletion_Springworks.pdf";
import publication_image from "../../assets/publication_image.png";

function MeWork() {
  const [isHovered, setIsHovered] = useState(false);
  const { hash } = useLocation(); //to scroll to particular section of the page

  useEffect(() => {
    //to scroll to particular section of the page
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  //usage defined in useInView.jsx
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const isInView1 = useInView(ref1);
  const isInView2 = useInView(ref2);
  const isInView3 = useInView(ref3);

  return (
    <Layout>
      <div className="flex justify-center items-center pt-8 pl-4 pr-4 pb-8 sm:p-16 md:p-24 md:pl-10 md:pr-8 md:pt-20 lg:pl-24 lg:pr-24 animate__animated animate__fadeIn z-2">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-12 items-stretch max-w-5xl mx-auto">
          {/* Left Container */}
          <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left bg-white p-6 gap-8 lg:gap-10">
            <img
              className="h-20 w-20 md:h-16 md:w-16 rounded-full"
              src={myimage}
              alt="profile"
            />

            <div className="flex flex-col">
              <p className="text-4xl xs:text-5xl xl:text-6xl font-normal leading-snug xs:leading-snug md:leading:normal xl:leading-normal">
                Hello!
              </p>

              <p className="text-4xl xs:text-5xl xl:text-6xl font-normal leading-snug xs:leading-snug md:leading-normal xl:leading-normal">
                I’m Prabhav Dev
              </p>
            </div>

            <div className="flex items-center justify-center gap-4 md:gap-2 lg:gap-4">
              <a href="#work_experience">
                <button className="hover:bg-black bg-white text-black hover:text-white font-normal text-md py-3 px-3 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                  Work Experience
                </button>
              </a>

              <div
                className="flex relative"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <button className="hover:bg-black bg-white text-black hover:text-white font-normal text-md py-3 px-3 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                  Tech Stack
                </button>

                {isHovered && (
                  <div className="absolute bottom-full right-0 mb-2 p-4 w-58 sm:w-64 text-sm bg-white border border-gray-300 rounded-xl shadow-lg flex flex-col text-start gap-2 animate__animated animate__zoomIn">
                    <p>
                      <strong>Core: </strong>HTML/CSS, JavaScript, Figma,
                      WordPress, MySQL, Git, Azure, API, Testing
                    </p>

                    <p>
                      <strong>Frameworks/Libraries: </strong>Bootstrap, SCSS,
                      Tailwind CSS, React.js, Next.js
                    </p>

                    <p>
                      <strong>Platforms: </strong>VS Code, Bitla Software
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Right Container */}
          <div className="flex flex-col justify-center md:justify-start items-center md:items-start text-center md:text-left bg-white p-6 gap-8">
            <p className="font-normal xs:leading-snug text-3xl xs:text-4xl">
              A software developer based in Bharat, India.
            </p>
            <p className="text-base md:text-md text-gray-500 xs:leading-relaxed">
              Unleashing the power of code to shape immersive web experiences
              that leave a lasting impression. Collaborate with me to bring your
              ideas to life. Let's make it happen!
            </p>
            <div className="flex items-center justify-center gap-4">
              <Link to="/connect">
                <button className="bg-black hover:bg-white hover:text-black text-white font-normal text-md py-3 px-4 border border-gray-300 rounded-xl transition duration-300 ease-in-out">
                  Discuss ideas
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex justify-center items-center pt-16 pl-6 pr-6 pb-8 sm:p-16 md:p-24 md:pl-12 md:pr-12 md:pt-20 lg:pl-24 lg:pr-24 lg:pt-24 bg-gray-50"
        id="work_experience"
      >
        <div className="grid grid-cols-1 gap-16 md:gap-20 items-stretch max-w-6xl mx-auto">
          <div className="text-3xl">Work Experience</div>

          <div
            className="grid grid-cols-1 lg:grid-cols-2 items-center gap-12 p-8 md:p-12 bg-white hover:shadow-md rounded-2xl"
            ref={ref1}
          >
            <div className="flex flex-col space-y-6">
              <div className="flex items-center space-x-3">
                <p className="text-3xl font-normal text-black">
                  SDE Intern -
                  <a
                    href="https://www.springworks.in/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline text-blue-700"
                  >
                    {" "}
                    Springworks
                  </a>
                </p>
              </div>
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2 text-blue-700">
                  <a
                    href="https://horizon360.toro.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline text-md lg:text-lg font-semibold"
                  >
                    Toro Horizon 360
                  </a>
                </div>
                <p className="text-gray-500 text-xs">
                  September 2024 - January 2024
                </p>
              </div>

              <div
                className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                  isInView1 ? "animate-fadeInUpShort" : ""
                }`}
              >
                <h4 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-4">
                  Development and Management
                </h4>
                <p className="text-gray-700 text-md">
                  Contributed to the development of complex features that
                  facilitated operations for U.S. clients, managing multiple
                  aspects such as hardware tracking, customer and equipment
                  management, time and materials tracking, scheduling and
                  dispatching, and route optimization.
                </p>
              </div>

              <div
                className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                  isInView1 ? "animate-fadeInUpMedium" : ""
                }`}
              >
                <p className="text-gray-700 text-md">
                  Utilized a diverse set of technologies and tools, including{" "}
                  <b>
                    React.js, SCSS, Bootstrap, styled-components, Formik, and
                    the Jest testing framework
                  </b>
                  . Implemented, integrated various APIs, and employed{" "}
                  <b>Context APIs</b> for state management. Enhanced
                  functionality with{" "}
                  <b>
                    calendar integration, Toast UI, Chart.js, and Yup schemas
                  </b>
                  , while leveraging numerous React components such as{" "}
                  <b>
                    react-google-maps, react-day-picker, react-geosuggest, and
                    react-toastify
                  </b>
                  , among others.
                </p>
              </div>

              <div>
                <a
                  href={LetterOfCompletion_Springworks}
                  download="LetterOfCompletion_Springworks.pdf"
                  className="hover:underline text-md lg:text-lg font-semibold text-blue-700"
                >
                  Letter of Completion
                </a>
              </div>

              <p className="text-gray-600 text-sm">
                <span className="font-semibold text-gray-800">
                  Tech stack:{" "}
                </span>
                <mark>
                  JavaScript, React.js, Chart.js, React components, CSS, SCSS,
                  Context APIs, Redux, Figma, Unit Testing, Jest, Git, Azure
                  DevOps
                </mark>
              </p>
            </div>

            <div
              className={`flex justify-center items-center  ${
                isInView1 ? "animate-fadeInFormView" : ""
              }`}
            >
              <a
                href="https://horizon360.toro.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={toro_image}
                  alt="Toro Horizon 360"
                  className="w-full h-auto rounded-xl border border-gray-200"
                />
              </a>
            </div>
          </div>
          <div
            className="grid grid-cols-1 lg:grid-cols-2 items-center gap-12 p-8 md:p-12 bg-white hover:shadow-md rounded-2xl"
            ref={ref2}
          >
            <div className="flex flex-col space-y-6">
              <div className="flex items-center space-x-3">
                <p className="text-3xl font-normal text-black">
                  SDE Intern -
                  <a
                    href="https://www.springworks.in/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline text-blue-700"
                  >
                    {" "}
                    Springworks
                  </a>
                </p>
              </div>
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2 text-blue-700">
                  <a
                    href="https://www.springworks.in/trivia/"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline text-md lg:text-lg font-semibold"
                  >
                    Trivia
                  </a>
                </div>
                <p className="text-gray-500 text-xs">May 2023 - August 2024</p>
              </div>

              <div
                className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                  isInView2 ? "animate-fadeInUpShort" : ""
                }`}
              >
                <h4 className="text-xl lg:text-2xl font-semibold text-gray-800 mb-4">
                  Development and Management
                </h4>
                <div className="flex flex-col gap-2">
                  <p className="text-gray-700 text-md">
                    As the frontend team lead for our trivia project, I focused
                    on fostering connections among employees. My work included
                    making the trivia platform fully responsive across all
                    devices and collaborating on the development of new game
                    features on platforms like Slack and Teams.
                  </p>
                </div>
              </div>

              <div
                className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                  isInView2 ? "animate-fadeInUpMedium" : ""
                }`}
              >
                <div className="flex flex-col gap-2">
                  <p className="text-gray-700 text-md">
                    For my first project, I contributed to a trivia platform
                    using React.js, employing Bootstrap, inline styles, and
                    Styled Components for design. I developed several features
                    based on Figma designs provided to me and conducted unit
                    tests with Jest and React Testing Library to ensure code
                    quality. Successfully completing this project led to my
                    promotion to Toro Horizon 360.
                  </p>
                </div>
              </div>

              <div>
                <a
                  href={LetterOfCompletion_Springworks}
                  download="LetterOfCompletion_Springworks.pdf"
                  className="hover:underline text-md lg:text-lg font-semibold text-blue-700"
                >
                  Letter of Completion
                </a>
              </div>

              <p className="text-gray-600 text-sm">
                <span className="font-semibold text-gray-800">
                  Tech stack:{" "}
                </span>
                <mark>
                  HTML, CSS, SCSS, Styled components, JavaScript, React.js,
                  React components Figma, Unit Testing, Jest, React Testing
                  Library Git, Azure DevOps
                </mark>
              </p>
            </div>

            <div
              className={`flex justify-center items-center ${
                isInView2 ? "animate-fadeInFormView" : ""
              }`}
            >
              <a
                href="https://www.springworks.in/trivia/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={trivia_image}
                  alt="Trivia"
                  className="w-full h-auto rounded-xl border border-gray-200"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex justify-center items-center pt-16 pl-6 pr-6 pb-8 sm:p-16 md:p-24 md:pl-12 md:pr-12 md:pt-20 lg:pl-24 lg:pr-24 bg-white"
        id="publications"
        ref={ref3}
      >
        <div className="grid grid-cols-1 gap-16 md:gap-20 items-stretch max-w-6xl mx-auto">
          <div className="text-3xl">Publications</div>

          <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-12 p-8 md:p-12 bg-white shadow-md rounded-2xl">
            <div className="flex flex-col space-y-6">
              <div className="flex items-center space-x-3">
                <p className="text-3xl font-normal text-black">WiFi Jammer</p>
              </div>
              <div className="flex flex-col space-y-1">
                <div className="flex items-center space-x-2 text-md lg:text-lg font-semibold">
                  Research and Development, UPES
                </div>
                <p className="text-gray-500 text-xs">
                  August 2022 - November 2022
                </p>
              </div>

              <div
                className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                  isInView3 ? "animate-fadeInUpShort" : ""
                }`}
              >
                <p className="text-gray-700 text-md">
                  Prabhav Dev, Keshav Kaushik, Rahul Negi.{" "}
                  <a
                    href="https://ieeexplore.ieee.org/document/10073447"
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline text-md lg:text-lg text-blue-700"
                  >
                    An electronic warfare approach for deploying a
                    software-based Wi-Fi Jammer
                  </a>
                  , International Conference on Contemporary Computing and
                  Informatics.
                </p>
              </div>

              <div
                className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                  isInView3 ? "animate-fadeInUpMedium" : ""
                }`}
              >
                <p className="text-gray-700 text-md">
                  Used the Airgeddon tool and carried out Disassociation Attack
                  allowing to disconnect all the target devices connected to
                  WiFi using DoS pursuit mode.
                </p>
              </div>

              <div
                className={`bg-white p-6 rounded-xl shadow-md transform transition-transform duration-300 hover:scale-105 ${
                  isInView3 ? "animate-fadeInUpLong" : ""
                }`}
              >
                <p className="text-gray-700 text-md">
                  Useful for military organizations, this technology can capture
                  drones by jamming their signals and providing false GPS data.
                </p>
              </div>

              <p className="text-gray-600 text-sm">
                <span className="font-semibold text-gray-800">
                  Tech stack:{" "}
                </span>
                <mark>
                  WiFi Jammer, DoS pursuit mode, Airgeddon Tool, Electronic
                  Warfare approach, Wireless Attacks
                </mark>
              </p>
            </div>

            <div
              className={`flex justify-center items-center ${
                isInView3 ? "animate-fadeInFormView" : ""
              }`}
            >
              <a
                href="https://ieeexplore.ieee.org/document/10073447"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={publication_image}
                  alt="WiFi Jammer"
                  className="w-full h-auto rounded-xl border border-gray-200"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center pt-16 pl-6 pr-6 pb-16 sm:p-16 md:p-24 md:pl-12 md:pr-12 md:pt-20 lg:pl-24 lg:pr-24 lg:pt-24 bg-gray-50">
        <div className="grid grid-cols-1 items-stretch max-w-5xl mx-auto">
          <p className="text-3xl xs:text-4xl md:text-5xl text-center leading-normal xs:leading-normal md:leading-normal">
            Join me in creating captivating and effective web solutions that
            stand out.
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default MeWork;
