import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import MeWork from "./pages/MeWork/MeWork";
import Gigging from "./pages/Gigging/Gigging";
import Canvas from "./pages/Canvas/Canvas";
import Models from "./pages/Models/Models";
import MeLife from "./pages/MeLife/MeLife";
import Connect from "./pages/Connect/Connect";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AudioProvider } from "./components/AudioProvider"; //when we navigate to different pages the audio should keep playing

const router = createBrowserRouter([
  {
    path: "/",
    element: <MeWork />,
  },
  {
    path: "gigging",
    element: <Gigging />,
  },
  // {
  //   path: "me_work",
  //   element: <MeWork />,
  // },
  {
    path: "canvas",
    element: <Canvas />,
  },
  {
    path: "models",
    element: <Models />,
  },
  {
    path: "connect",
    element: <Connect />,
  },
  {
    path: "me_life",
    element: <MeLife />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AudioProvider>
      <RouterProvider router={router} />
    </AudioProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
